<template>
  <div>
    <Search
      class="mb-5"
      :placeholder="$route.name === 'Campaign' ? 'Search by handle' : 'Search by campaign'"
      @onChange="filter"
    />

    <p v-if="!videos.length">No videos</p>

    <p v-if="videos.length && !filteredList.length">No result</p>

    <div class="grid grid-cols-4 gap-6">
      <div v-for="(video, index) in shownClips" :key="index" class="m-auto min-w-full max-w-screen-xl shadow-xl">
        <div class="rounded border-2 border-b-0 border-blue-light post-content">
          <img :src="video.thumbnail" class="rounded-t" />

          <div class="p-4 pt-3">
            <p>
              {{ video.meta && video.meta.title ? video.meta.title.substring(0, 30) + "..." : "Untitled clip" }}
            </p>

            <div class="flex items-center mb-3 w-full">
              <p v-if="$route.name === 'Campaign'" class="flex justify-start items-center mb-1 font-bold">
                <Avatar
                  size="small"
                  class="inline-flex mr-1"
                  :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${
                    video.handle || 'frymealiiiver'
                  }/avatar.png`"
                />

                <Button
                  class="text-base font-medium text-white-default button ellipsis"
                  :url="`/profile/${video.handle}`"
                  :label="video.handle"
                  kind="link"
                />
              </p>

              <p class="ml-auto text-xs font-bold text-blue-lighter">
                {{
                  new Date(video.createdDate).toLocaleDateString(undefined, {
                    weekday: "short",
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                  })
                }}
              </p>
            </div>

            <div class="flex gap-2">
              <Tag class="inline-flex w-auto text-center opacity-70" label="Twitch" :color="'#a970ff'" />

              <Tag
                v-if="$route.name === 'StreamerProfile'"
                class="inline-flex w-auto text-center opacity-70"
                :label="video.campaignTitle.substring(0, 10) + (video.campaignTitle.length > 10 ? '..' : '')"
                :color="'#34C66E'"
              />

              <Tag
                v-if="video.meta && video.meta.language && video.meta.language.length"
                class="inline-flex w-auto text-center opacity-70"
                :label="convertLanguageCode(video.meta.language)"
                :random-color="true"
              />

              <Tag
                v-if="video.meta && video.meta.game_name && video.meta.game_name.length"
                class="inline-flex w-auto text-center opacity-70"
                :label="video.meta.game_name"
                :random-color="true"
              />
            </div>
          </div>

          <div class="mt-2 w-full">
            <button class="p-2 w-1/2 bg-primary-default hover:bg-primary-light rounded-bl" @click="downloadFile(video)">
              Download
            </button>

            <button class="p-2 w-1/2 bg-blue-light hover:bg-blue-lighter" @click="playVid(video)">Play</button>
          </div>
        </div>
      </div>

      <Modal v-if="showVideo.show" @closeModal="closeModal">
        <Tile class="overflow-y-auto p-4 md:p-8" style="max-height: 80vh">
          <div v-if="$store.state.userInfo.type == 'admin'" class="justify-end">
            <Dropdown
              class="justify-end w-full"
              :button-text="selectedCampaign"
              :items="campaignList"
              :is-show-more="false"
              @change="changeCampaign"
            />
          </div>
          <div class="inline-flex items-end">
            <Avatar
              v-if="$route.name === 'Campaign'"
              size="large"
              class="inline-flex mr-4"
              :src="`https://storage.googleapis.com/cavea-avatar-service/twitch/${
                showVideo.handle || 'frymealiiiver'
              }/avatar.png`"
            />

            <Title
              :class="['mt-4', $route.name === 'Campaign' ? 'w-full' : 'w-full']"
              :title="$route.name === 'Campaign' ? showVideo.data.handle : showVideo.data.campaignTitle"
              :size="$route.name === 'Campaign' ? 'large' : 'small'"
            />

            <Title
              :class="[
                'flex justify-end place-self-auto mt-4',
                $route.name === 'Campaign' ? 'w-full' : 'w-auto min-w-max',
              ]"
              color="#2D2849"
              :title="
                new Date(showVideo.data.createdDate).toLocaleDateString(undefined, {
                  weekday: 'short',
                  year: 'numeric',
                  month: 'short',
                  day: 'numeric',
                })
              "
              size="small"
            />
          </div>

          <hr class="my-4 border-blue-lighter" />

          <video class="rounded shadow-lg" controls autoplay>
            <source :src="showVideo.data.uploadLink" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <div class="flex flex-col gap-4 justify-center mt-5">
            <button
              class="justify-center p-2 mx-auto w-3/4 bg-primary-default hover:bg-primary-light rounded"
              @click="downloadFile(showVideo.data)"
            >
              Download
            </button>

            <a
              v-if="showVideo && showVideo.data && showVideo.data.clipLinks && showVideo.data.clipLinks.length"
              class="justify-center p-2 mx-auto w-3/4 text-center rounded"
              :href="showVideo.data.clipLinks[0]"
              style="background-color: #6441a4"
              target="_blank"
              rel="noopener noreferrer"
            >
              View on Twitch
            </a>

            <button
              v-if="$store.state.userInfo.type == 'admin'"
              class="justify-center p-2 mx-auto w-3/4 bg-signal-red rounded"
              :disabled="showVideo.data.campaignDetected != null && !showVideo.data.campaignDetected"
              @click="updateDetection(showVideo.data._id, false)"
            >
              {{ showVideo.data.campaignDetected == null ? "Mark as campaign not found on clip" : "Removed" }}
            </button>
          </div>
        </Tile>
      </Modal>
    </div>
  </div>
</template>

<script>
import { Tag, Tile, Title, Avatar, Button, Search } from "cavea-design-system";
import axios from "axios";
import { mapGetters } from "vuex";
import languageCodeToName from "@/lib/helpers/languageCodeToName";

export default {
  name: "StreamerClips",

  components: {
    Tag,
    Title,
    Avatar,
    Search,
    Tile,
    Button,
    Modal: () => import("@/components/Modal"),
    Dropdown: () => import("@/components/Dropdown"),
  },

  props: ["handles", "campaign", "streamerid", "profilecampaigns"],

  data() {
    return {
      videoPlayModal: false,
      search: "",
      showVideo: {
        show: false,
        data: null,
      },
      videos: [],
      isScrollable: false,
      maxClips: 8,
    };
  },

  computed: {
    ...mapGetters(["getCampaigns"]),

    shownClips() {
      return this.filteredList; // .slice(0, this.maxClips);
    },

    filteredList() {
      return this.videos.filter((video) =>
        video[this.campaign ? "handle" : "campaignTitle"].toLowerCase().includes(this.search.toLowerCase())
      );
    },

    selectedCampaign() {
      return this.campaignList.findIndex((x) => x.id === this.showVideo.data.campaignId) !== -1
        ? this.campaignList[this.campaignList.findIndex((x) => x.id === this.showVideo.data.campaignId)].label
        : "campaign not found";
    },

    campaignList() {
      return this.getCampaigns.map((campaign) => ({
        id: campaign._id,
        label: campaign.title,
      }));
    },
  },

  mounted() {
    this.getVideoClip();
  },

  methods: {
    changeCampaign(val) {
      this.showVideo.data.campaignId = val.item.id;

      axios
        .put(`${process.env.VUE_APP_API_URL}/clips/${this.showVideo.data._id}`, {
          campaignId: val.item.id,
        })
        .then(() => {
          this.getVideoClip();
        });
    },

    downloadFile(vid) {
      axios({
        url: vid.uploadLink,
        method: "GET",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${this.$route.name === "Campaign" ? vid.handle : vid.campaignTitle}.mp4`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },

    closeModal() {
      this.showVideo.show = false;
      this.showVideo.data = null;
    },

    playVid(vid) {
      this.showVideo.data = vid;
      this.showVideo.show = true;
    },

    filter(val) {
      this.search = val;
    },

    updateDetection(clipid, state) {
      this.showVideo.data.campaignDetected = false;
      axios
        .put(`${process.env.VUE_APP_API_URL}/clips/${clipid}`, {
          campaignDetected: state,
        })
        .then(() => {
          this.getVideoClip();
        });
    },

    async getVideoClip() {
      const _that = this;
      let url = "";

      switch (this.$route.name) {
        case "Campaign":
          url = `${process.env.VUE_APP_API_URL}/clips/campaign/${this.campaign}`;
          break;
        case "StreamerProfile":
          url = `${process.env.VUE_APP_API_URL}/clips/content-creator/${this.streamerid}`;
          break;
        default:
          break;
      }

      const clips = await axios
        .get(url)
        .then((res) => res?.data?.clips ?? [])
        .catch((error) => console.error("getVideoClip", error));

      if (clips?.length && this.$route.name === "Campaign") {
        const idToHandle = new Map();
        const campaign = _that.getCampaigns.findIndex((c) => c?._id === clips[0].campaignId);

        clips.forEach((clip) => {
          if (idToHandle.has(clip.contentCreatorId)) {
            clip.handle = idToHandle.get(clip.contentCreatorId);
          } else {
            const index = _that.getCampaigns[campaign]?.handles?.findIndex(
              (x) => x?.contentCreatorId?._id === clip?.contentCreatorId
            );

            if (index !== -1) {
              idToHandle.set(clip?.contentCreatorId, _that.getCampaigns[campaign]?.handles[index].handle);

              this.$set(clip, "handle", _that.getCampaigns[campaign]?.handles[index].handle);
            }
          }
        });
      }

      if (clips?.length && _that.$route.name === "StreamerProfile") {
        clips.forEach((clip) => {
          const campaign = _that.profilecampaigns.findIndex((c) => c?._id === clip.campaignId);
          if (campaign !== -1) {
            this.$set(clip, "campaignTitle", _that.profilecampaigns[campaign].title);
          }
        });
      }
      this.videos = clips;

      if (this.videos?.length > this.maxClips) {
        this.isScrollable = true;
        this.loadMoreClips();
      }
    },

    convertLanguageCode(languageCode) {
      return languageCodeToName(languageCode);
    },

    loadMoreClips() {
      if (this.isScrollable) {
        window.onscroll = () => {
          if (
            this.videos?.length &&
            this.maxClips < this.videos.length &&
            window.innerHeight + window.scrollY >= document.body.offsetHeight - 100
          ) {
            this.maxClips += 16;
          }
        };
      } else {
        window.onscroll = (e) => {
          e.preventDefault();
        };
      }
    },
  },
};
</script>
